<template>
  <div>
    <el-table :data="data" style="margin-top: 30px" :cell-style="tableRowStyle">
      <el-table-column prop="date" label="商品信息" align="center" width="250">
        <template slot-scope="scope">
          <div class="flex_row_aic" @click="handleClick(scope.row)">
            <img style="width: 87px; height: 87px" :src="scope.row.image.url" alt="" />
            <div class="info">
              <p class="shenglue_2 bold">{{ scope.row.title }}</p>
              <p class="c6">{{ scope.row.specification }}</p>
              <div class="flex_row">
                <div v-for="sub in scope.row.tags" :key="sub.title" :style="`color: #${sub.color}; border: 1px solid #${sub.color};`" class="tag mr_10">
                  {{ sub.title }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="厂家" align="center" width="180">
        <template slot-scope="scope">
          <span class="shenglue_1">{{ scope.row.manufacturer }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jzl" label="件装量" align="center" />
      <el-table-column prop="add_sl" label="中包装" align="center" />
      <el-table-column prop="validity_date" label="效期" align="center" />
      <el-table-column prop="kc_str" label="库存" align="center" />
      <el-table-column  label="会员价" align="center"  width="140px">
        <template slot-scope="{ row }">
          {{row.price}}
          <span v-if="row.price1!=''" style="text-decoration: line-through;color:rgb(116, 113, 113);font-size: 12px;">{{row.price1}}</span>
          <div v-if="row.price_qhj!=''" style="color:#ff4c4c;font-size: 12px;">{{row.price_qhj}}</div>
        </template>

      </el-table-column>
      <el-table-column label="数量" align="center" width="150">
        <template slot-scope="{ row }">
          <!-- add_sl:步长；step:最小值；show_cart：是否能够购买 -->
          <el-input-number
            size="mini"
            :disabled="row.show_cart == 102"
            :step="Number(row.add_sl)"
            :min="Number(row.step)"
            :max="Number(row.max_number)"
            v-model="row.cart_number"
            @blur="blur(row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <img v-if="row.show_cart == 101" class="mr_10" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/cart.png" alt="" @click="handleAdd(row)" />
          <img v-if="row.is_collect == 102" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/heart.png" alt="" @click="handleCollect(row)" />
          <img v-if="row.is_collect == 101" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/heart_sel.png" alt="" @click="cancleCollect(row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 6) {
        return "color:#F93232;!important;";
      }
    },
    handleAdd(row) {
      let { show_cart, can_buy, can_buy_str, id, cart_number } = row;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }

      this.$api("goods.addCart", { id, number: cart_number }).then(() => {
        this.$store.dispatch("getBadge");
        this.$message.success("加入购物车成功");
      });
    },
    blur(item) {
      if (item.add_sl != 1) {
        let num = Number(item.cart_number) / Number(item.add_sl);

        if (num % 1 !== 0) {
          item.cart_number = Number(item.add_sl) * (parseInt(num) + 1);
        }
        this.data.forEach((e) => {
          if (e.id == item.id) e = item;
        });
      }
    },
    handleCollect(row) {
      this.$api("goods.addCollect", { id: row.id }).then(() => {
        this.$message.success("收藏成功");
        this.data.forEach((v) => {
          if (row.id == v.id) {
            v.is_collect = 101;
          }
        });
      });
    },
    cancleCollect(row) {
      this.$api("account.delCollect", { id: row.id }).then(() => {
        this.$message.success("取消收藏成功");
        this.data.forEach((v) => {
          if (row.id == v.id) {
            v.is_collect = 102;
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  text-align: left;
  margin-left: 10px;
  .jx_img {
    width: 52px;
    height: 17px;
    margin-bottom: 8px;
    position: relative;
    img {
      position: absolute;
    }
    p {
      position: absolute;
      top: -2px;
      left: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #fefefe;
      text-align: center;
    }
    i {
      position: absolute;
      color: #fefefe;
      right: 10px;
      top: 3px;
    }
  }
  .tag {
    padding: 0 5px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
  }
  .c6 {
    font-size: 12px;
  }
}
.el-input-number--mini {
  width: 100px;
}
</style>
